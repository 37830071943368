<template>
    <div>
        <el-dialog
            title="上传项目合同"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="700px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="项目合同" prop="contractFiles">
                    <!-- <el-button type="primary"  @click="checkDeviceCode">点击上传</el-button> -->
                    <multi-file-choose 
                        ref="uploadDev"
                        :accept="'.pdf'" 
                        :readonlyName="'项目合同_'" 
                        placeholder="只能选择.pdf文件" 
                        :taxFiles.sync='form.contractFiles' :max="1"></multi-file-choose>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    import multiFileChoose from '../../components/formcy/multi-file-choose.vue';
    export default {
        components:{
            multiFileChoose
        },
        data() {
            return {
                dialogVisible: false,
                id: '',
                form: {
                    contractFiles: '',
                },
                rules: {
					contractFiles: [{required: true, message:'请上传项目合同', trigger: 'change'}],
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.form = {
                        contractFiles: '',
                    },
                    this.$refs['uploadDev'].fileList = []
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            /**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				this.$Axios._get({
                    url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
                    showLoading: true,
                    params: {
                        incomeId: this.id,
                    },
                })
                .then(({
                    data
                }) => {
                    if(data.contractFile) {
                        this.$refs['uploadDev'].fileList = [{
                            name: data.contractFile,
                            url: data.contractFile
                        }]
                    }
                    console.log(JSON.stringify(data));
                });
			},
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认上传项目合同？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.changeContractFile,
                                method: "post",
                                params: {
                                    incomeId: this.id,
                                    contractFile: this.form.contractFiles[0].url,
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$emit('success');
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
</style>