<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">项目管理</span>
            <div class="a-flex-rcc">
                <le-export-btn :pageParam="pageParam"></le-export-btn>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24 myblue" @click="handlerAddProject" v-if="$_has(26)">项目</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-icon-search label="项目名称" v-model="pageParam.params.searchKey" placeholder="请输入项目名称" />
                <le-input-icon-search label="项目ID" v-model="pageParam.params.incomeId" placeholder="请输入项目ID" />
                <le-select-local-search label="项目状态" v-model="pageParam.params.searchStatus"
                    :options="optionsProject" />
                <le-select-num-range label="我方收益" :minData.sync="pageParam.params.minRate"
                    :maxData.sync="pageParam.params.maxRate" :options="optionsShouyi" unit="%"></le-select-num-range>
                <!-- <le-select-local-search label="视图模式" v-model="viewMode" :options="viewModeOptions" /> -->
                <le-input-district label="省市区" :province.sync="pageParam.params.provinceCode"
                    :city.sync="pageParam.params.cityCode" :area.sync="pageParam.params.districtCode">
                </le-input-district>
                <le-company-under-select placeholder="请选择商户（可输入搜索）" label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-select-local-search label="关联项目" v-model="pageParam.params.joinIncome" :clearable='false'
					:options="yesOrNoDic" />
            </le-search-form>

            <le-pagview ref="projectlist" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.projectlistTable">
                <el-table ref="projectlistTable" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="id" label="项目ID">
                    </el-table-column>
                    <el-table-column prop="name" label="项目名称" :width="viewMode ? 360 : 280">
                    </el-table-column>
                    <el-table-column prop="companyName" label="所属商户" :width="viewMode ? 272 : 300">
                    </el-table-column>
                    <el-table-column label="站点数量/设备数量" width="152" v-if="!viewMode">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationSize }}/{{ row.deviceNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="我方占比" v-if="!viewMode" :width="130">
                        <template slot-scope="{ row }">
                            <span>{{ row.maxRate==row.minRate?(row.maxRate / 100 +'%'):(row.minRate/100+'% ~ '+row.maxRate/100+'%') }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="本月收益(元)" width="128" v-if="!viewMode">
                        <template slot-scope="{ row }">
                            <span>{{
                                    util.numFormat(row.monthOrderIncome)
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总收益(元)" width="144" v-if="!viewMode">
                        <template slot-scope="{ row }">
                            <span>{{
                                    util.numFormat(row.hisOrderIncome)
                            }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="statusText" label="状态">
                    </el-table-column>

                    <el-table-column prop="createTimeText" label="签订日期" width="200" v-if="!viewMode">
                    </el-table-column>
                    <el-table-column label="是否正在变更" width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.isChanging ? "是" : "否" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTimeText" label="到期日期" width="200" v-if="viewMode">
                    </el-table-column>
                    <el-table-column prop="runDays" label="运行天数" v-if="!viewMode">
                    </el-table-column>
                    <el-table-column label="项目合同" width="200">
                        <template slot-scope="{ row }">
                            <a :href="row.contractFile" class="a-custor-p">{{ row.contractFileText }}</a>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width='100'>
                        <template slot-scope="{ row }">
							<div class="a-flex-rcc">
								<div @click="handlerGoDetail(row.id, row.status)" class="a-cursor-p">
									<el-tooltip class="item" effect="dark" content="项目详情" placement="top" >
										<img src="../../assets/icon/option-detail.png" class="a-wh-16"/>
									</el-tooltip>
								</div>
								<div @click="handlerEditPro(row.id)" class="a-cursor-p a-ml-16" v-if="$_has(26) && company.isSystem && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)">
									<el-tooltip class="item" effect="dark" content="变更项目" placement="top" >
										<img src="../../assets/icon/option-edit.png" class="a-wh-16"/>
									</el-tooltip>
								</div>
                                <div @click="handlerUploadContractFile(row)" class="a-cursor-p a-ml-16" v-if="$_has(26)">
									<el-tooltip class="item" effect="dark" content="上传项目合同" placement="top" >
										<img src="../../assets/icon/option-upgrade.png" class="a-wh-16"/>
									</el-tooltip>
								</div>
							</div>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <upload-contract-file ref="uploadContractFile" @success='handleUploadContractFileSuccess'></upload-contract-file>
    </div>
</template>

<script>
import func from "../../axios/index.js";
import util from "../../utils/util.js";
import UploadContractFile from './child/uploadContractFile.vue';
import { mapState } from 'vuex';
export default {
    components: {
        UploadContractFile
    },
    data() {
        return {
            util: util,
            selectedOptions: [],
            tableData: [],
            optionsProject: [
                {
                    label: "服务中",
                    value: 1,
                },
                {
                    label: "未生效",
                    value: 2,
                },
                {
                    label: "已失效",
                    value: 3,
                },
            ],
            optionsShouyi: [
                {
                    minData: 5,
                    maxData: 30,
                },
                {
                    minData: 31,
                    maxData: 60,
                },
                {
                    minData: 61,
                    maxData: 95,
                },
            ],
            pageParam: null,
            viewMode: 0,
            viewModeOptions: [
                {
                    label: "项目视图",
                    value: 0,
                },
                {
                    label: "站点视图",
                    value: 1,
                },
            ],
            yesOrNoDic: []
        };
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    watch: {
        viewMode: {
            handler: function (val) {
                this.handlerSearch();
            },
        },
    },
    created() {
        let apiurl = !!this.viewMode
            ? this.$Config.apiUrl.getIncomeStatByStationInfoList
            : this.$Config.apiUrl.getIncomeStatInfoList;

        this.pageParam = {
            url: apiurl,
            method: "post",
            params: {
                searchKey: "",
                incomeId: '',//项目ID
                provinceCode: "",
                cityCode: "",
                districtCode: "",
                minRate: "",
                maxRate: "",
                searchStatus: "",
                companyId: "",
                joinIncome: '0'
            },
            freshCtrl: 1,
        };
        this.$getDic('yesOrNo','select').then(res=>{ this.yesOrNoDic = res; })
    },
    mounted() { },
    activated () {
		this.pageParam.freshCtrl++;
	},
    methods: {
        //获取车辆列表
        setTableData(data) {

            if (data.length) {
                data = data.map((item, index) => {
                    let contractFileList = []
                    if (item.contractFile) {
                        contractFileList = item.contractFile.split("/");

                    }
                    return item = {
                        ...item,
                        contractFileText: contractFileList.length ?
                            contractFileList[contractFileList.length - 1] : '无',
                    };



                });
            }
            this.tableData = data;

        },
        handlerRest() {
            this.pageParam.params = {
                searchKey: "",
                incomeId: '',//项目ID
                provinceCode: "",
                cityCode: "",
                districtCode: "",
                minRate: "",
                maxRate: "",
                searchStatus: "",
                companyId: "",
                joinIncome: '0'
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['projectlist'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        handlerAddProject() {
            this.$router.push({
                path: "/project/project-add",
            });
        },
		handlerEditPro(id){
			this.$router.push({
			    path: "/project/project-add",
				query: {
				    incomeId: id,
				},
			});
		},
        handlerGoDetail(id, state) {
            if (state == 1 || state == 2) {
                this.$router.push({
                    path: "/project/project-confirmed",
                    query: {
                        id: id,
                    },
                });
            }
            if (state == 4) {
                this.$router.push({
                    path: "/project/project-detail",
                    query: {
                        id: id,
                    },
                });
            }
        },
        handlerUploadContractFile (datas) {
            this.$refs['uploadContractFile'].id = datas.id
            this.$refs['uploadContractFile'].getIncomeDetail()
            this.$refs['uploadContractFile'].dialogVisible = true
        },
        handleUploadContractFileSuccess () {
            this.pageParam.freshCtrl++;
        }
    },
};
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .s-search-label {
        width: 92px !important;
    }
</style>
